.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  background: #ffffff;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  color: #313131;
  line-height: 1.2;
}

.m-basket-fixed {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 3px 13px rgba(210, 45, 27, 0.59);
  border-radius: 999rem;
  background-image: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
  padding: 4px;
  border-radius: 999rem;
  background-image: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  z-index: 6;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-basket-fixed:hover {
  opacity: 0.8;
  text-decoration: none;
}

.m-basket-fixed__icon {
  max-width: 24px;
  max-height: 24px;
}

.m-basket-fixed__counter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 26px;
  height: 26px;
  padding: 4px;
  border-radius: 999rem;
  width: 26px;
  height: 26px;
  border: 1px solid #d12c1a;
  background-color: #ffffff;
  text-align: center;
  color: #cf2a18;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}

.m-header {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  transition: all 0.2s ease-out;
  z-index: 9;
}

.m-header_fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.m-header_fixed .m-header__top {
  display: none;
}

.m-header_fixed .m-header__main-logo {
  display: inline-block;
}

.m-header-mob {
  display: none;
  position: relative;
  padding: 14px 0;
}

.m-header__top {
  padding: 14px 0;
  border-bottom: 1px solid #d3d3d3;
}

.m-header__logo {
  display: block;
  position: relative;
  max-width: 350px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header__logo img,
.m-header__logo svg {
  max-width: 100%;
}

.m-header__logo-text {
  display: block;
  position: absolute;
  bottom: 24px;
  right: 0;
  margin: 0;
  color: #ae8a4d;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.m-header__logo:hover {
  opacity: 0.8;
  text-decoration: none;
}

.m-header__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}

.m-header__row_info {
  padding: 14px 0 24px 0;
}

.m-header__info {
  display: inline-block;
  margin: 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-header__info a {
  color: #313131;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header__info a:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-header__nav {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.m-header__nav > li {
  display: inline-block;
}

.m-header__nav > li:last-child a {
  margin: 0;
}

.m-header__nav > li > a {
  display: inline-block;
  position: relative;
  margin: 0 50px 0 0;
  padding: 11px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header__nav > li > a:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-header__nav-drop {
  display: none;
  position: absolute;
  top: 100%;
  left: -32px;
  width: 250px;
  margin: 0;
  padding: 22px 28px;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-color: #ffffff;
  z-index: 4;
}

.m-header__nav-drop li {
  display: block;
}

.m-header__nav-drop li a {
  display: block;
  padding: 8px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header__nav-drop li a:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-header__nav-dropper {
  position: relative;
}

.m-header__nav-dropper:hover > a {
  color: #cf2a18;
}

.m-header__nav-dropper:hover > ul {
  display: block;
}

.m-header__phone {
  display: inline-block;
  color: #313131;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header__phone:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-header__button {
  display: inline-block;
  padding: 9px 30px;
  border-radius: 999rem;
  border: 1px solid #b4935b;
  background-color: transparent;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header__button:hover {
  border-color: #b4935b;
  background: #b4935b;
  color: #313131;
  text-decoration: none;
}

.m-header__main {
  padding: 12px 0;
}

.m-header__main-logo {
  display: none;
  max-width: 52px;
  max-height: 52px;
  margin: 0 42px 0 0;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header__main-logo img,
.m-header__main-logo svg {
  max-width: 100%;
  max-height: 100%;
}

.m-header__main-logo:hover {
  opacity: 0.8;
  text-decoration: none;
}

.m-header_group {
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.m-header__menu {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.m-header__menu li {
  display: inline-block;
}

.m-header__menu li a {
  display: inline-flex;
  align-items: center;
  margin-right: 36px;
  color: #313131;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header__menu li a img,
.m-header__menu li a svg {
  max-width: 24px;
  max-height: 24px;
  margin-right: 10px;
}

.m-header__menu li a:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-header__basket {
  display: inline-block;
  position: relative;
}

.m-header__basket-icon {
  max-width: 24px;
  max-height: 24px;
  margin-right: 13px;
}

.m-header__basket-counter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  height: 26px;
  margin-left: 16px;
  padding: 4px;
  border-radius: 999rem;
  background-color: #ffffff;
  text-align: center;
  color: #cf2a18;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}

.m-header__basket-button {
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 12px 12px 12px 20px;
  border-radius: 999rem;
  background-image: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header__basket-button:hover {
  opacity: 0.8;
  text-decoration: none;
}

.m-header-basket {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 500px;
  margin: 26px 0 0 0;
  padding: 30px 30px 20px 30px;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-color: #ffffff;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.m-header-basket:hover .m-header-basket__footer:before {
  opacity: 0;
}

.m-header-basket__footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 30px 0 0 0;
  padding: 20px 0 0 0;
  border-top: 1px solid #d3d3d3;
}

.m-header-basket__footer:before {
  display: block;
  position: absolute;
  content: "";
  bottom: 100%;
  margin-bottom: 30px;
  pointer-events: none;
  left: 0;
  width: 100%;
  height: 150px;
  background-image: linear-gradient(to top, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  transition: all 0.2s ease-out;
}

.m-header-basket__button {
  display: inline-block;
  margin: 0;
  padding: 14px 24px;
  border: 0;
  outline: none;
  border-radius: 25px;
  background-image: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header-basket__button:hover {
  opacity: 0.8;
  text-decoration: none;
}

.m-header-basket__price {
  display: inline-block;
  margin: 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
}

.m-header-basket ::-webkit-scrollbar {
  width: 3px;
}

.m-header-basket ::-webkit-scrollbar-track {
  background: #d3d3d3;
}

.m-header-basket ::-webkit-scrollbar-thumb {
  background: #cf2a18;
}

.m-header-basket ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.m-header-basket__cards {
  display: block;
  position: relative;
  width: 100%;
  height: 600px;
  overflow-y: auto;
}

.m-header-basket__card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
  /* Firefox */
}

.m-header-basket__card:last-child {
  margin: 0;
}

.m-header-basket__card-tag {
  display: inline-block;
  margin: 0 5px 5px 0;
  padding: 2px 4px;
  border-radius: 2px;
  background-color: #ff0000;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 500;
  text-transform: uppercase;
}

.m-header-basket__card-tag_hit {
  background: #ff0000;
}

.m-header-basket__card-tag_hot {
  background: #ff7e00;
}

.m-header-basket__card-tag_hot {
  background: #ff7e00;
}

.m-header-basket__card-image {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 80px;
  height: 90px;
  margin: 0 20px 0 0;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.m-header-basket__card-content {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 0;
}

.m-header-basket__card-title {
  display: block;
  height: 24px;
  overflow: hidden;
  margin: 0;
  color: #313131;
  font-family: Neucha;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header-basket__card-title:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-header-basket__card-info {
  display: block;
  height: 16px;
  overflow: hidden;
  margin: 7px 0 11px 0;
  opacity: 0.5;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-header-basket__card-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.m-header-basket__card-counter {
  display: inline-block;
  position: relative;
  margin-right: 30px;
}

.m-header-basket__card-counter input {
  display: block;
  width: 100%;
  max-width: 100px;
  margin: 0;
  padding: 6px 32px;
  border: 0;
  outline: none;
  border-radius: 999rem;
  border: 1px solid #cf2a18;
  background-color: transparent;
  text-align: center;
}

.m-header-basket__card input::-webkit-outer-spin-button,
.m-header-basket__card input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.m-header-basket__card input[type="number"] {
  -moz-appearance: textfield;
}

.m-header-basket__card-price {
  display: inline-block;
  margin: 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.m-header-basket__card-delete {
  display: inline-block;
  opacity: 0.9;
  filter: grayscale(1);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header-basket__card-delete img,
.m-header-basket__card-delete svg {
  max-width: 20px;
  max-height: 20px;
}

.m-header-basket__card-delete:hover {
  opacity: 1;
  filter: grayscale(0);
  text-decoration: none;
}

.m-header-basket__card-minus {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 100%;
  border-left: 1px solid #cf2a18;
  border-top: 1px solid #cf2a18;
  border-bottom: 1px solid #cf2a18;
  border-radius: 999rem 0 0 999rem;
  background: #ffffff url("../images/nav_minus.png") no-repeat center center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header-basket__card-minus:hover {
  background: #e5e5e5 url("../images/nav_minus.png") no-repeat center center;
  text-decoration: none;
}

.m-header-basket__card-plus {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 100%;
  border-right: 1px solid #cf2a18;
  border-top: 1px solid #cf2a18;
  border-bottom: 1px solid #cf2a18;
  border-radius: 0 999rem 999rem 0;
  background: #ffffff url("../images/nav_plus.png") no-repeat center center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-header-basket__card-plus:hover {
  background: #e5e5e5 url("../images/nav_plus.png") no-repeat center center;
  text-decoration: none;
}

.m-welcome {
  display: block;
  position: relative;
  width: 100%;
}

.m-welcome-card {
  display: block;
  position: relative;
  width: 100%;
  background-position: center bottom;
  background-size: cover;
}

.m-welcome-card__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 660px;
  padding: 20px 0;
  overflow-y: auto;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-welcome-card__content:hover {
  opacity: 1;
  text-decoration: none;
}

.m-welcome-card__title {
  display: block;
  max-height: 240px;
  overflow: hidden;
  margin: 0;
  color: #313131;
  font-family: Neucha;
  font-size: 100px;
  font-weight: 400;
}

.m-welcome-card__text {
  display: block;
  max-height: 78px;
  margin: 50px 0 0 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 400;
}

.m-welcome .swiper-pagination {
  width: auto;
  bottom: 100px;
  left: calc((100vw - 1170px) / 2);
}

.m-welcome .swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  margin: 0 10px !important;
  opacity: 1;
  background: #e7e7e7;
  transition: all 0.2s ease-out;
}

.m-welcome .swiper-pagination-bullet-active {
  box-shadow: 0 1px 9px rgba(207, 42, 24, 0.48);
  background-color: #cf2a18;
}

.m-home-goods {
  display: block;
  position: relative;
  width: 100%;
  padding: 90px 0 110px 0;
}

.m-home-goods__centered {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.m-home-goods__centered_good {
  padding-bottom: 60px;
}

.m-home-goods__text {
  display: block;
  margin: 25px 0 78px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}

.m-home-goods__button {
  display: inline-block;
  margin: 30px 0 0 0;
  padding: 24px 88px;
  border-radius: 999rem;
  background-image: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-home-goods__button:hover {
  opacity: 0.8;
  color: #ffffff;
  text-decoration: none;
}

.m-title {
  display: inline-block;
  position: relative;
  margin: 0 110px;
  color: #313131;
  font-family: Neucha;
  font-size: 70px;
  font-weight: 400;
}

.m-title__centered {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 60px 0;
}

.m-title__centered .swiper-button-next,
.m-title__centered .swiper-button-prev {
  position: absolute;
  top: 350px;
  right: auto;
  left: auto;
  bottom: auto;
  margin: 0;
  width: 70px;
  height: 70px;
  outline: none;
  background: transparent;
  z-index: 2;
  transition: all 0.2s ease-out;
}

.m-title__centered .swiper-button-next:before, .m-title__centered .swiper-button-next:after,
.m-title__centered .swiper-button-prev:before,
.m-title__centered .swiper-button-prev:after {
  display: none;
}

.m-title__centered .swiper-button-prev {
  left: 0;
  background: url("../images/icon_prev.png") no-repeat center center;
  background-size: contain;
}

.m-title__centered .swiper-button-prev:hover {
  background: url("../images/icon_prev_hover.png") no-repeat center center;
}

.m-title__centered .swiper-button-next {
  right: 0;
  background: url("../images/icon_next.png") no-repeat center center;
  background-size: contain;
}

.m-title__centered .swiper-button-next:hover {
  background: url("../images/icon_next_hover.png") no-repeat center center;
}

.m-title__button {
  display: inline-block;
  width: 100%;
  max-width: 250px;
  margin: 0;
  padding: 20px 12px;
  border-radius: 999rem;
  border: 1px solid #b4935b;
  background: transparent;
  text-align: center;
  color: #313131;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-title__button:hover {
  border-color: #b4935c;
  background: #b4935b;
  color: #313131;
  text-decoration: none;
}

.m-title__arrows {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 250px;
}

.m-title:before {
  display: block;
  position: absolute;
  content: "";
  left: -110px;
  top: 25px;
  width: 72px;
  height: 38px;
  background: url("../images/icon_waves.png") no-repeat center center;
  background-size: contain;
  z-index: 1;
}

.m-title:after {
  display: block;
  position: absolute;
  content: "";
  right: -110px;
  top: 25px;
  width: 72px;
  height: 38px;
  background: url("../images/icon_waves.png") no-repeat center center;
  background-size: contain;
  z-index: 1;
}

.m-cards {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 0 60px 0;
}

.m-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 270px;
  margin: 0 auto 30px auto;
  padding: 10px;
  border-radius: 15px;
  background: #ffffff;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-card__link {
  display: block;
  position: relative;
  width: 100%;
  color: #313131;
  text-decoration: none;
}

.m-card__link:hover {
  color: #313131;
  text-decoration: none;
}

.m-card__image {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 280px;
  padding: 0 0 10px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.m-card__tag {
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ff0000;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.m-card__tag_hit {
  background: #ff0000;
}

.m-card__tag_new {
  background: #34aab8;
}

.m-card__tag_hot {
  background: #ff7e00;
}

.m-card__content {
  display: block;
  position: relative;
  width: 100%;
  height: 162px;
  overflow: hidden;
}

.m-card__title {
  display: block;
  margin: 0;
  color: #313131;
  font-family: Neucha;
  font-size: 30px;
  font-weight: 400;
}

.m-card__text {
  display: block;
  margin: 10px 0 0 0;
  opacity: 0.5;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-card__info {
  display: block;
  margin: 20px 0 0 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.m-card__switch {
  display: inline-block;
  margin: 20px 10px 0 0;
}

.m-card__switch:last-child {
  margin-right: 0;
}

.m-card__switch input {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.m-card__switch input:checked ~ span {
  background: #cf2a18;
  color: #ffffff;
}

.m-card__switch span {
  margin: 0;
  padding: 0 10px;
  border-radius: 999rem;
  background-color: #dfdfdf;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-card__switch span:hover {
  background: #cf2a18;
  color: #ffffff;
  text-decoration: none;
}

.m-card__footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 30px 0 0 0;
}

.m-card__price {
  display: inline-block;
  color: #313131;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
}

.m-card__button {
  display: inline-block;
  margin: 0;
  padding: 7px 27px;
  border: 0;
  outline: none;
  border-radius: 999rem;
  border: 1px solid #cf2a18;
  background-color: transparent;
  text-align: center;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-card__button:hover {
  background: #cf2a18;
  color: #ffffff;
  text-decoration: none;
}

.m-card:hover {
  background: #ffffff url("../images/card_bg.png") no-repeat 0 0;
  background-size: 100% 100%;
  text-decoration: none;
}

.m-why {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
  padding-bottom: 60px;
  border-bottom: 1px solid #d3d3d3;
  background: url("../images/why_bg.png") no-repeat center bottom 35px;
}

.m-why_borderless {
  border: 0;
}

.m-why__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding: 0;
}

.m-why__row_1 {
  max-width: 850px;
  padding-top: 75px;
}

.m-why__row_2 {
  padding: 50px 80px 0 230px;
}

.m-why-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 260px;
  margin: 0;
}

.m-why-card__icon {
  display: block;
  max-width: 80px;
  max-height: 80px;
  margin: 0 0 30px 0;
}

.m-why-card__title {
  display: block;
  margin: 0 0 20px 0;
  color: #313131;
  font-family: Neucha;
  font-size: 30px;
  font-weight: 400;
}

.m-why-card__text {
  display: block;
  margin: 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
}

.m-delivery {
  display: block;
  position: relative;
  width: 100%;
  padding: 60px 0 75px 0;
  background: url("../images/delivery_pay.png") no-repeat left 10px bottom 0;
  z-index: 2;
}

.m-delivery__title {
  display: block;
  margin: -15px 0 0 0;
  color: #313131;
  font-family: Neucha;
  font-size: 70px;
  font-weight: 400;
}

.m-delivery-card {
  display: block;
  max-width: 355px;
  margin: 0;
}

.m-delivery-card__title {
  display: block;
  margin: 0 0 26px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
}

.m-delivery-card__text {
  display: block;
  margin: 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
}

.m-delivery__info {
  display: block;
  margin: 60px 0 26px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
}

.m-delivery__button {
  display: inline-block;
  margin: 0;
  padding: 14px 64px;
  border-radius: 999rem;
  background: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-delivery__button:hover {
  opacity: 0.8;
  color: #ffffff;
  text-decoration: none;
}

.m-reviews {
  display: block;
  position: relative;
  width: 100%;
  margin: -40px 0 0 0;
  padding: 80px 0 50px 0;
  background: url("../images/reviews_bg.png") no-repeat 0 0;
  background-size: 100% 100%;
  z-index: 1;
}

.m-reviews .swiper-pagination {
  display: none;
  width: 100%;
  left: auto;
  bottom: 160px;
}

.m-reviews .swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  margin: 0 10px !important;
  opacity: 1;
  background: #e7e7e7;
  transition: all 0.2s ease-out;
}

.m-reviews .swiper-pagination-bullet-active {
  box-shadow: 0 1px 9px rgba(207, 42, 24, 0.48);
  background-color: #cf2a18;
}

.m-reviews__cards {
  display: block;
  position: relative;
  width: calc(100% + 30px);
  margin-left: -15px;
  padding: 10px 15px 20px 15px;
}

.m-reviews-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
  padding: 26px 30px 22px 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
}

.m-reviews-card__text {
  display: block;
  height: 264px;
  overflow: hidden;
  margin: 0 0 24px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.m-reviews-card__row {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.m-reviews-card__image {
  display: inline-block;
  width: 83px;
  height: 83px;
  margin-right: 25px;
  background-position: center center;
  background-size: cover;
}

.m-reviews-card__date {
  display: block;
  margin: 0 0 15px 0;
  opacity: 0.5;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-reviews-card__name {
  display: block;
  margin: 0 0 12px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
}

.m-reviews-card__from {
  color: #a6a6a6;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-reviews-card__from img,
.m-reviews-card__from svg {
  max-width: 100px;
  max-height: 30px;
}

.m-reviews .m-title__centered .swiper-button-next, .m-reviews .m-title__centered .swiper-button-prev {
  margin-top: -20px;
  opacity: 0.5;
}

.m-seo {
  display: block;
  position: relative;
  width: 100%;
  margin: -60px 0 0 0;
  overflow: hidden;
  background: url("../images/seo_bg.png") no-repeat left 85% center;
}

.m-seo__more {
  display: none;
  margin: 0;
  padding: 20px 0 0 0;
  color: #cf2a18;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.m-seo__more:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-seo__image {
  display: block;
  position: absolute;
  top: 0;
  right: 20px;
  width: 50vw;
  height: 640px;
  background-position: bottom right;
  background-size: cover;
}

.m-seo__title {
  display: block;
  margin: 0 0 20px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
}

.m-seo__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-height: 640px;
  padding: 80px 0;
}

.m-seo__content_opened {
  min-height: auto !important;
  max-height: 100% !important;
}

.m-seo__content p {
  display: block;
  margin: 0 0 12px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.m-seo__content h1,
.m-seo__content h2,
.m-seo__content h3,
.m-seo__content h4,
.m-seo__content h5,
.m-seo__content h6 {
  display: block;
  margin: 0 0 20px 0;
  color: #313131;
  font-family: Neucha;
  font-weight: 400;
}

.m-seo__content h1 {
  font-size: 100px;
}

.m-seo__content h2 {
  font-size: 70px;
}

.m-seo__content h3 {
  font-size: 30px;
}

.m-seo__content h4 {
  font-size: 20px;
}

.m-seo__content h5 {
  font-size: 16px;
}

.m-seo__content h6 {
  font-size: 12px;
}

.m-seo__content a {
  color: #e42c18;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.m-seo__content a:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-seo__content ul {
  display: block;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-seo__content ul li {
  display: block;
  position: relative;
  margin: 0 0 14px 0;
  padding: 0 0 0 12px;
  background: url("../images/icon_ul.png") no-repeat left top 5px;
}

.m-home-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 30px 0;
}

.m-home-logo img,
.m-home-logo svg {
  max-width: 270px;
  max-height: 270px;
}

.m-footer {
  display: block;
  position: relative;
  width: 100%;
  padding: 50px 0 15px 0;
  background: #ffffff;
}

.m-footer__link {
  display: block;
  margin: 0 0 36px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-footer__link:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-footer__nav {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.m-footer__nav li {
  display: block;
}

.m-footer__nav li a {
  display: block;
  margin: 0 0 16px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-footer__nav li a:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-footer__title {
  display: block;
  margin: 0 0 24px 0;
  opacity: 0.5;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
}

.m-footer__pay {
  display: block;
  max-width: 100%;
  max-height: 64px;
}

.m-footer__social {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 50px;
}

.m-footer__social a {
  display: inline-block;
  margin: 0 50px 10px 0;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-footer__social a:hover {
  transform: scale(0.9);
  text-decoration: none;
}

.m-footer__phone {
  display: block;
  margin: 0 0 12px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-footer__phone:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-footer__button {
  display: block;
  width: 100%;
  padding: 9px 6px;
  border-radius: 999rem;
  border: 1px solid #b4935b;
  background-color: transparent;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-footer__button:hover {
  border-color: #b4935b;
  background: #b4935b;
  color: #313131;
  text-decoration: none;
}

.m-footer__creators {
  display: block;
  margin: 70px 0 0 0;
  opacity: 0.5;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.m-footer__creators:hover {
  opacity: 1;
  color: #cf2a18;
  text-decoration: none;
}

.m-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
}

.m-modal__centered {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}

.m-modal__close {
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 20px;
  z-index: 1;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-modal__close:hover, .m-modal__close:active, .m-modal__close:focus {
  opacity: 0.9;
  text-decoration: none;
}

.m-modal__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 570px;
  padding: 65px 85px 70px 85px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background: #ffffff;
}

.m-modal__title {
  display: block;
  margin: 0 0 10px 0;
  color: #313131;
  font-family: Neucha;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

.m-modal__text {
  display: block;
  margin: 0 0 40px 0;
  opacity: 0.7;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
  text-align: center;
}

.m-modal__button {
  display: block;
  width: 100%;
  padding: 21px;
  border: 0;
  outline: none;
  border-radius: 999rem;
  background: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-modal__button:hover {
  opacity: 0.8;
  color: #ffffff;
  text-decoration: none;
}

.m-modal__info {
  display: block;
  margin: 14px 0 0 0;
  opacity: 0.5;
  color: #313131;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
}

.m-breadcrumps {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 30px 0 70px 0;
  list-style: none;
  color: rgba(49, 49, 49, 0.5);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
}

.m-breadcrumps a {
  opacity: 0.8;
  color: #cf2a18;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-breadcrumps a:hover {
  opacity: 1;
  color: #313131;
  text-decoration: none;
}

.m-breadcrumps img,
.m-breadcrumps svg {
  display: inline-block;
  margin: 0 10px;
}

.m-breadcrumps li {
  display: inline-block;
}

.m-good {
  display: block;
  position: relative;
  width: 100%;
}

.m-good__image {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 670px;
  height: 630px;
  border-radius: 5px;
  background-color: #f6f5f5;
}

.m-good__image img,
.m-good__image svg {
  max-width: 100%;
  max-height: 100%;
}

.m-good__tags {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.m-good__tag {
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ff0000;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.m-good__tag_hit {
  background: #ff0000;
}

.m-good__tag_new {
  background: #34aab8;
}

.m-good__tag_hot {
  background: #ff7e00;
}

.m-good__content {
  display: block;
  padding-left: 20px;
}

.m-good__title {
  display: block;
  margin: 0 0 40px 0;
  color: #313131;
  font-family: Neucha;
  font-size: 70px;
  font-weight: 400;
  line-height: 0.93;
}

.m-good__price {
  display: block;
  margin: 0 0 44px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
}

.m-good__subtitle {
  display: block;
  margin: 0 0 10px 0;
  opacity: 0.5;
  color: #000000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.m-good__text {
  display: block;
  margin: 40px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
}

.m-good__button {
  display: block;
  width: 100%;
  position: relative;
  padding: 22px 12px;
  border: 0;
  outline: none;
  border-radius: 999rem;
  background-image: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-good__button:hover {
  opacity: 0.8;
  text-decoration: none;
}

.m-good__switch {
  display: inline-block;
  margin: 20px 10px 0 0;
}

.m-good__switch:last-child {
  margin-right: 0;
}

.m-good__switch input {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.m-good__switch input:checked ~ span {
  background: #cf2a18;
  color: #ffffff;
}

.m-good__switch span {
  margin: 0;
  padding: 0 10px;
  border-radius: 999rem;
  background-color: #dfdfdf;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-good__switch span:hover {
  background: #cf2a18;
  color: #ffffff;
  text-decoration: none;
}

.m-good-stocks {
  display: block;
  position: relative;
  padding-bottom: 120px;
}

.m-good-stocks .m-home-goods__centered {
  padding-bottom: 50px;
}

.m-stock {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
  border-radius: 10px;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-stock:hover {
  transform: scale(0.99);
  text-decoration: none;
}

.m-stock__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 765px;
  min-height: 400px;
  padding: 20px 190px 20px 60px;
  background: url("../images/stock_bg.png") no-repeat center center;
  background-size: 100% 100%;
}

.m-stock__title {
  display: block;
  margin: 0 0 28px 0;
  color: #ffffff;
  font-family: Neucha;
  font-size: 30px;
  font-weight: 400;
}

.m-stock__text {
  display: block;
  margin: 0 0 30px 0;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
}

.m-stock__link {
  display: inline-block;
  border: 0;
  outline: none;
  padding: 15px 40px;
  border-radius: 25px;
  background-color: #b99a66;
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-stock__link:hover {
  color: #ffffff;
  opacity: 0.8;
  text-decoration: none;
}

.m-hr {
  display: block;
  position: relative;
  width: 100%;
  height: 1px;
  background: #d3d3d3;
}

.m-quote {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 180px;
  background: url("../images/quote_bg.png") no-repeat left calc((100vw - 1200px) / 2 + 430px) top 20px;
}

.m-quote__image {
  display: block;
  position: relative;
  width: 100%;
  max-width: 370px;
  height: 500px;
  margin-left: -60px;
  border-radius: 5px;
  background-color: #f2f2f2;
}

.m-quote__image img,
.m-quote__image svg {
  display: block;
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  top: 60px;
  left: 60px;
}

.m-quote__content {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 65px;
  padding-left: 60px;
}

.m-quote__text {
  display: block;
  margin: 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.65;
}

.m-quote__name {
  display: block;
  margin: 78px 0 0 0;
  color: #313131;
  font-family: Neucha;
  font-size: 30px;
  font-weight: 400;
}

.m-quote__who {
  display: block;
  margin: 4px 0 0 0;
  opacity: 0.7;
  color: #000000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.m-about {
  display: block;
  position: relative;
  width: 100%;
  padding: 120px 0;
  background: url("../images/about_bg.png") no-repeat left calc((100vw - 1200px) / 2 + 140px) top 300px;
}

.m-about__title {
  display: block;
  margin: 0 0 40px 0;
  color: #313131;
  font-family: Neucha;
  font-size: 70px;
  font-weight: 400;
}

.m-about__content {
  display: block;
  position: relative;
  width: 100%;
  padding-right: 70px;
}

.m-about__content p {
  display: block;
  margin: 0 0 25px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
}

.m-about__image {
  display: block;
  position: relative;
  width: calc(100% + 30px);
  max-width: 400px;
  height: 500px;
  margin: 0 0 0 -30px;
  border-radius: 5px;
  background-position: center center;
  background-size: cover;
}

.m-about__cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 30px 0 0 0;
}

.m-about-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 195px;
  margin: 0 55px 30px 0;
  border-top: 2px solid #b99a66;
}

.m-about-card__number {
  display: block;
  margin: 25px 0 0 0;
  color: #b99a66;
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 500;
}

.m-about-card__text {
  display: block;
  margin: 0;
  opacity: 0.8;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-how {
  display: block;
  position: relative;
  width: 100%;
  padding: 20px 0 120px 0;
  background: url("../images/how_bg.png") no-repeat left calc((100vw - 1200px) / 2) top 60px;
}

.m-how__cards {
  display: block;
  padding-top: 65px;
}

.m-how-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 270px;
  margin: 0 auto 30px auto;
  padding: 25px 20px 35px 20px;
  border-radius: 5px;
  background-color: #b99a66;
  text-align: center;
}

.m-how-card__number {
  display: block;
  margin: 0 0 24px 0;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
}

.m-how-card__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  margin: 0 0 27px 0;
  padding: 20px;
  border-radius: 999rem;
  background-color: #ffffff;
}

.m-how-card__image img,
.m-how-card__image svg {
  max-width: 100%;
  max-height: 100%;
}

.m-how-card__title {
  display: block;
  margin: 0;
  color: #fffefe;
  font-family: Neucha;
  font-size: 20px;
  font-weight: 400;
}

.m-article {
  display: block;
  position: relative;
  width: 100%;
}

.m-article h1,
.m-article h2,
.m-article h3,
.m-article h4,
.m-article h5,
.m-article h6 {
  display: block;
  margin: 0 0 20px 0;
  color: #313131;
  font-family: Neucha;
  font-weight: 400;
}

.m-article h1 {
  font-size: 100px;
}

.m-article h2 {
  font-size: 70px;
}

.m-article h3 {
  font-size: 30px;
}

.m-article h4 {
  font-size: 20px;
}

.m-article h5 {
  font-size: 16px;
}

.m-article h6 {
  font-size: 12px;
}

.m-article p {
  display: block;
  margin: 0 0 50px 0;
  color: rgba(49, 49, 49, 0.9);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
}

.m-article a {
  color: #e42c18;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.m-article a:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-article ul {
  display: block;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-article ul li {
  display: block;
  position: relative;
  margin: 0 0 14px 0;
  padding: 0 0 0 12px;
  background: url("../images/icon_ul.png") no-repeat left top 5px;
}

.m-article img {
  display: block;
  max-width: 100%;
  max-height: 600px;
  margin: 0 auto 50px auto;
  border-radius: 10px;
}

.m-article .table {
  margin: 100px 0 30px 0;
  text-align: center;
}

.m-article .table-striped tbody tr:nth-of-type(odd) {
  background: transparent;
}

.m-article .table-striped tbody tr:nth-of-type(even) {
  background: rgba(185, 154, 102, 0.08);
}

.m-article .table td,
.m-article .table th {
  padding: 30px 20px;
  border: 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
}

.m-article .table thead th {
  padding: 12px 20px;
  border: 0;
  border-bottom: 1px solid #d3d3d3;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.m-block {
  display: block;
  position: relative;
  width: 100%;
  padding: 70px 0;
}

.m-block .m-home-goods__centered {
  padding-bottom: 55px;
}

.m-block__content {
  display: block;
  position: relative;
}

.m-block__content h1,
.m-block__content h2,
.m-block__content h3,
.m-block__content h4,
.m-block__content h5,
.m-block__content h6 {
  display: block;
  margin: 0 0 20px 0;
  color: #313131;
  font-family: Neucha;
  font-weight: 400;
}

.m-block__content h1 {
  font-size: 100px;
}

.m-block__content h2 {
  font-size: 70px;
}

.m-block__content h3 {
  font-size: 30px;
}

.m-block__content h4 {
  font-size: 20px;
}

.m-block__content h5 {
  font-size: 16px;
}

.m-block__content h6 {
  font-size: 12px;
}

.m-block__content p {
  display: block;
  margin: 0 0 30px 0;
  color: rgba(49, 49, 49, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.m-block__content a {
  color: #e42c18;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.m-block__content a:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-block__content ul {
  display: block;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-block__content ul li {
  display: block;
  position: relative;
  margin: 0 0 14px 0;
  padding: 0 0 0 12px;
  background: url("../images/icon_ul.png") no-repeat left top 5px;
}

.m-block__content img {
  display: block;
  max-width: 100%;
  max-height: 600px;
  margin: 0 auto 50px auto;
  border-radius: 10px;
}

.m-zones {
  display: block;
  position: relative;
  width: 100%;
  padding: 90px 0;
}

.m-zones .m-home-goods__centered {
  padding-bottom: 60px;
}

.m-zones__image {
  display: block;
  width: 100%;
  max-width: 870px;
  max-height: 870px;
}

.m-zones-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 170px;
  margin: 0 auto 10px auto;
  padding: 10px;
  border-radius: 5px;
  background-color: #fafafa;
}

.m-zones-card__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 999rem;
  background-color: #2bd25d;
}

.m-zones-card__icon img,
.m-zones-card__icon svg {
  max-width: 100%;
  max-height: 100%;
}

.m-zones-card__icon_yellow {
  background: #ffed00;
}

.m-zones-card__icon_red {
  background: #ff6900;
}

.m-zones-card__title {
  display: block;
  margin: 8px 0 5px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.m-zones-card__text {
  display: block;
  margin: 0;
  opacity: 0.6;
  color: #313131;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
}

.m-basket {
  display: block;
  position: relative;
  width: 100%;
  padding: 30px 0 150px 0;
}

.m-basket__title {
  display: block;
  margin: 0 0 35px 0;
  color: #313131;
  font-family: Neucha;
  font-size: 70px;
  font-weight: 400;
}

.m-basket__subtitles {
  display: block;
  position: relative;
  padding: 14px 0;
  border-bottom: 1px solid #d3d3d3;
  color: rgba(49, 49, 49, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.m-basket-card {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid #d3d3d3;
  /* Firefox */
}

.m-basket-card_delivery .m-basket-card__image {
  flex: 0 0 65px;
  height: 65px;
  margin-left: 6px;
  margin-right: 25px;
}

.m-basket-card__delete {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  opacity: 0.9;
  z-index: 2;
  filter: grayscale(1);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-basket-card__delete img,
.m-basket-card__delete svg {
  max-width: 20px;
  max-height: 20px;
}

.m-basket-card__delete:hover {
  opacity: 1;
  filter: grayscale(0);
  text-decoration: none;
}

.m-basket-card__row {
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
}

.m-basket-card__row:hover {
  text-decoration: none;
}

.m-basket-card__image {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 90px;
  height: 90px;
  margin-right: 10px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.m-basket-card__tag {
  display: inline-block;
  margin: 0 5px 5px 0;
  padding: 2px 4px;
  border-radius: 2px;
  background-color: #ff0000;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 500;
  text-transform: uppercase;
}

.m-basket-card__tag_hit {
  background: #ff0000;
}

.m-basket-card__tag_hot {
  background: #ff7e00;
}

.m-basket-card__tag_hot {
  background: #ff7e00;
}

.m-basket-card__title {
  display: block;
  margin: 0;
  color: #313131;
  font-family: Neucha;
  font-size: 30px;
  font-weight: 400;
}

.m-basket-card__counter {
  display: inline-block;
  position: relative;
  margin: 20px 0;
}

.m-basket-card__counter input {
  display: block;
  width: 100%;
  max-width: 100px;
  margin: 0;
  padding: 3px 32px;
  border: 0;
  outline: none;
  border-radius: 999rem;
  border: 1px solid #cf2a18;
  background-color: transparent;
  text-align: center;
}

.m-basket-card input::-webkit-outer-spin-button,
.m-basket-card input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.m-basket-card input[type="number"] {
  -moz-appearance: textfield;
}

.m-basket-card__minus {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 100%;
  border-left: 1px solid #cf2a18;
  border-top: 1px solid #cf2a18;
  border-bottom: 1px solid #cf2a18;
  border-radius: 999rem 0 0 999rem;
  background: #ffffff url("../images/nav_minus.png") no-repeat center center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-basket-card__minus:hover {
  background: #e5e5e5 url("../images/nav_minus.png") no-repeat center center;
  text-decoration: none;
}

.m-basket-card__plus {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 100%;
  border-right: 1px solid #cf2a18;
  border-top: 1px solid #cf2a18;
  border-bottom: 1px solid #cf2a18;
  border-radius: 0 999rem 999rem 0;
  background: #ffffff url("../images/nav_plus.png") no-repeat center center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-basket-card__plus:hover {
  background: #e5e5e5 url("../images/nav_plus.png") no-repeat center center;
  text-decoration: none;
}

.m-basket-card__switch {
  display: inline-block;
  margin: 10px 10px 10px 0;
}

.m-basket-card__switch:last-child {
  margin-right: 0;
}

.m-basket-card__switch input {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.m-basket-card__switch input:checked ~ span {
  background: #cf2a18;
  color: #ffffff;
}

.m-basket-card__switch span {
  margin: 0;
  padding: 0 10px;
  border-radius: 999rem;
  background-color: #dfdfdf;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-basket-card__switch span:hover {
  background: #cf2a18;
  color: #ffffff;
  text-decoration: none;
}

.m-basket-card__price {
  display: block;
  color: #313131;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.m-basket__footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 20px 0;
}

.m-basket__summ {
  display: inline-block;
  margin: 15px 20px;
  color: #313131;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
}

.m-basket__button {
  display: inline-block;
  margin: 0;
  padding: 18px 40px;
  border: 0;
  outline: none;
  border-radius: 999rem;
  background-image: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-basket__button:hover {
  opacity: 0.8;
  text-decoration: none;
}

.m-form {
  display: block;
  position: relative;
  width: 100%;
  padding: 30px 0 150px 0;
}

.m-form__title {
  display: block;
  margin: 0;
  color: #313131;
  font-family: Neucha;
  font-size: 70px;
  font-weight: 400;
}

.m-form__text {
  display: block;
  margin: 10px 0 50px 0;
  opacity: 0.7;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-form__block {
  display: block;
  position: relative;
  width: 100%;
  margin-top: -1px;
  padding: 20px 0 30px 0;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}

.m-form__subtitle {
  display: block;
  margin: 0 0 20px 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
}

.m-form__input {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 10px 0;
}

.m-form__input input {
  display: block;
  width: 100%;
  border-radius: 5px;
  outline: none;
  margin: 0;
  padding: 27px;
  border: 1px solid #dbdbdb;
  background-color: #f7f7f7;
  color: #313131;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.m-form__input input:focus ~ span, .m-form__input input:valid ~ span {
  top: 15px;
  opacity: 0.7;
  color: #313131;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  text-shadow: 0 0 2px #ffffff;
}

.m-form__input textarea {
  display: block;
  width: 100%;
  border-radius: 5px;
  outline: none;
  margin: 0;
  padding: 27px;
  border: 1px solid #dbdbdb;
  background-color: #f7f7f7;
  color: #313131;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.m-form__input textarea:focus ~ span, .m-form__input textarea:valid ~ span {
  top: 15px;
  opacity: 0.7;
  color: #313131;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  text-shadow: 0 0 2px #ffffff;
}

.m-form__input span {
  position: absolute;
  left: 27px;
  top: 40px;
  margin-top: -10px;
  opacity: 0.5;
  color: #313131;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  pointer-events: none;
  transition: all 0.2s ease-out;
  z-index: 2;
}

.m-form__choose {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 200px;
  margin: 0 0 30px 0;
  padding: 0;
  line-height: 1;
}

.m-form__choose input {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.m-form__choose input:checked ~ span {
  background: url("../images/checkbox_checked.png") no-repeat left center;
}

.m-form__choose span {
  display: block;
  margin: 0;
  padding: 4px 0 4px 30px;
  background: url("../images/checkbox.png") no-repeat left center;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.m-form__type {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 5px 0;
  padding: 0;
  line-height: 1;
}

.m-form__type-title {
  display: block;
  margin: 0;
  color: #313131;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.m-form__type-text {
  display: block;
  margin: 6px 0 0 0;
  opacity: 0.6;
  color: #313131;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
}

.m-form__type input {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.m-form__type input:checked ~ div {
  background: #fafafa url("../images/checkbox_checked.png") no-repeat left 25px center;
}

.m-form__type > div {
  display: block;
  margin: 0;
  margin-top: -15px;
  padding: 24px 24px 24px 56px;
  background: #fafafa url("../images/checkbox.png") no-repeat left 25px center;
}

.m-form__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 100%;
}

.m-form__row .m-form__input {
  max-width: 49%;
}

.m-form__delivery {
  display: block;
  position: relative;
  width: 100%;
}

.m-form__myself {
  display: block;
  position: relative;
  width: 100%;
}

.m-form__counter {
  display: inline-block;
  position: relative;
  margin-right: 30px;
}

.m-form__counter-title {
  display: block;
  margin: 0 0 10px 0;
  opacity: 0.9;
  color: #313131;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
}

.m-form__counter input {
  display: block;
  width: 100%;
  max-width: 120px;
  margin: 0;
  padding: 13px 45px;
  border: 0;
  outline: none;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  background-color: transparent;
  text-align: center;
}

.m-form input::-webkit-outer-spin-button,
.m-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.m-form input[type="number"] {
  -moz-appearance: textfield;
}

.m-form__minus {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 100%;
  border-left: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 5px 0 0 5px;
  background: #ffffff url("../images/nav_minus.png") no-repeat center center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-form__minus:hover {
  background: #f7f7f7 url("../images/nav_minus.png") no-repeat center center;
  text-decoration: none;
}

.m-form__plus {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 100%;
  border-right: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 0 5px 5px 0;
  background: #ffffff url("../images/nav_plus.png") no-repeat center center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-form__plus:hover {
  background: #f7f7f7 url("../images/nav_plus.png") no-repeat center center;
  text-decoration: none;
}

.m-stocks {
  display: block;
  position: relative;
  width: 100%;
  padding: 30px 0 150px 0;
}

.m-stocks .m-home-goods__centered {
  padding-bottom: 50px;
}

.m-pagination {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 20px 0 0 0;
  list-style: none;
}

.m-pagination li {
  display: inline-block;
}

.m-pagination li img,
.m-pagination li svg {
  max-width: 100%;
  max-height: 100%;
}

.m-pagination li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 70px;
  height: 70px;
  margin: 0 10px 10px 10px;
  padding: 4px;
  border-radius: 999rem;
  border: 1px solid #b4935b;
  background-color: #ffffff;
  text-align: center;
  color: #313131;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-pagination li a:hover {
  transform: scale(0.95);
  color: #313131;
  text-decoration: none;
}

.m-pagination_active a {
  background: #af9563 !important;
  color: #ffffff !important;
}

.m-contacts {
  display: block;
  position: relative;
  width: 100%;
  padding: 60px 0 35px 0;
  background: url("../images/contacts_bg.png") no-repeat right calc((100vw - 1200px) / 2 + 60px) top;
}

.m-contacts__main {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 70px 0 0 0;
  padding: 40px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: #ffffff;
}

.m-contacts-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.m-contacts-card__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  padding: 4px;
  border-radius: 999rem;
  background-color: #f7f7f7;
}

.m-contacts-card__icon img,
.m-contacts-card__icon svg {
  max-width: 100%;
  max-height: 100%;
}

.m-contacts-card__title {
  display: block;
  margin: 0;
  color: #313131;
  font-family: Neucha;
  font-size: 18px;
  font-weight: 400;
}

.m-contacts-card__link {
  display: block;
  margin: 0;
  color: #313131;
  font-family: Neucha;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.m-contacts-card__link:hover {
  color: #cf2a18;
  text-decoration: none;
}

.m-contacts-card__what {
  display: block;
  margin: 0;
  opacity: 0.5;
  color: #313131;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.m-feedback {
  display: block;
  position: relative;
  width: 100%;
  margin: 100px 0 150px 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: #ffffff;
}

.m-feedback__form {
  padding: 50px 90px 45px 90px;
}

.m-feedback .m-form__input {
  margin-bottom: 20px;
}

.m-feedback_light {
  border-radius: 0 15px 15px 0;
  background-color: #fafafa;
}

.m-feedback:before {
  display: block;
  content: "";
  position: absolute;
  top: 40px;
  left: -40px;
  width: 1312px;
  height: 560px;
  background: url("../images/feedback_bg.png") no-repeat center center;
  background-size: contain;
  z-index: 2;
  pointer-events: none;
}

#map {
  display: block;
  position: relative;
  width: 100%;
  height: 550px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .m-welcome-card {
    background-position: left calc((100vw - 1200px) / 2 / 2) bottom;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1600px) {
  .m-header-basket__cards {
    height: 400px;
  }
}

@media (max-width: 1600px) {
  .m-card__image {
    height: 200px;
  }
  .m-title__centered .swiper-button-next, .m-title__centered .swiper-button-prev {
    top: 270px;
  }
}

@media (max-width: 1400px) {
  .m-feedback:before {
    display: none;
  }
}

@media (max-width: 1199px) {
  .m-welcome-card__content {
    max-width: 100%;
  }
  .m-header__row {
    display: block;
    width: 100%;
  }
  .m-header__info {
    display: block;
    margin: 0 0 6px 0;
    text-align: center;
  }
  .m-header__row_info {
    padding: 6px 0;
  }
  .m-header__logo {
    display: none;
  }
  .m-header__phone {
    display: block;
    text-align: center;
  }
  .m-header__nav-dropper:hover > ul {
    display: none;
  }
  .m-header__button {
    display: block;
    max-width: 190px;
    margin: 6px auto 0 auto;
    text-align: center;
  }
  .m-header__nav {
    display: block;
    width: 100%;
    padding: 6px 0;
  }
  .m-header__nav > li {
    display: block;
  }
  .m-header__nav > li > a {
    display: block;
    margin: 0;
    padding: 6px;
    text-align: center;
  }
  .m-header__menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
  }
  .m-header__menu li {
    display: inline-block;
  }
  .m-header__menu li a {
    display: inline-block;
    padding: 8px;
  }
  .m-header__main {
    display: none;
  }
  .m-header__top {
    display: none;
  }
  .m-header-mob {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .m-header-mob_fixed .m-header-mob__logo_big {
    display: none;
  }
  .m-header-mob_fixed .m-header-mob__logo_small {
    display: block;
  }
  .m-header-mob_fixed .m-header-mob__nav {
    top: 20px;
  }
  .m-header-mob_fixed .m-header-mob__phone {
    top: 17px;
  }
  .m-header-mob__logo img,
  .m-header-mob__logo svg {
    max-width: 100%;
    max-height: 100px;
  }
  .m-header-mob__logo_small {
    display: none;
  }
  .m-header-mob__nav {
    display: block;
    position: absolute;
    left: 0;
    top: 36px;
  }
  .m-header-mob__phone {
    display: block;
    position: absolute;
    right: 0;
    top: 33px;
  }
  .m-modal__content {
    padding: 40px 20px 20px 20px;
  }
  .m-modal__text {
    margin-bottom: 20px;
  }
  .m-header__basket {
    display: none;
  }
  .m-basket-fixed {
    display: flex;
  }
  .m-welcome-card {
    background-position: right top;
    background-size: auto 230px;
    background-repeat: no-repeat;
  }
  .m-welcome-card__content {
    height: auto;
    padding: 220px 0 70px 0;
    text-align: center;
  }
  .m-welcome-card__title {
    width: 100%;
    max-height: 96px;
    margin-bottom: 15px;
    color: #313131;
    font-family: Neucha;
    font-size: 40px;
    font-weight: 400;
  }
  .m-welcome-card__text {
    width: 100%;
    max-height: 38px;
    margin: 0;
    color: #313131;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
  }
  .m-title:before {
    display: none;
  }
  .m-title:after {
    display: none;
  }
  .m-why__row_2 {
    padding: 0;
  }
  .m-welcome .swiper-pagination {
    width: 100%;
    left: auto;
    bottom: 35px;
  }
  .m-title {
    margin: 0;
    font-size: 40px;
    font-weight: 400;
  }
  .m-home-goods__text {
    margin: 10px 0 20px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
  .m-home-goods {
    padding: 20px 0 50px 0;
  }
  .m-home-goods__button {
    margin-top: 10px;
  }
  .m-card__content {
    text-align: center;
  }
  .m-card__mob {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
  }
  .m-card__switch {
    margin-left: 5px;
    margin-right: 5px;
  }
  .m-header_fixed .m-header__main-logo {
    display: none;
  }
  .m-card__info {
    text-align: center;
  }
  .m-title__button {
    display: block;
    position: absolute;
    bottom: 50px;
    width: 100%;
    max-width: calc(100% - 30px);
    margin: 30px auto 0 auto;
    padding: 24px 12px;
    border-radius: 999rem;
    background-image: linear-gradient(to top, #cf2a18 0%, #ee4c3a 100%);
    text-align: center;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 700;
    z-index: 2;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
  }
  .m-title__button:hover {
    opacity: 0.8;
    color: #ffffff;
    text-decoration: none;
  }
  .m-title__centered .swiper-button-next,
  .m-title__centered .swiper-button-prev {
    position: absolute;
    top: 320px;
    width: 50px;
    height: 32px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 3;
  }
  .m-title__centered .swiper-button-next {
    right: 0;
    background-image: url("../images/icon_next_mob.png");
  }
  .m-title__centered .swiper-button-prev {
    left: 0;
    background-image: url("../images/icon_prev_mob.png");
  }
  .container {
    position: relative;
  }
  .m-cards {
    padding-bottom: 150px;
  }
  .m-title {
    width: 100%;
    text-align: center;
  }
  .m-title__centered {
    padding-bottom: 20px;
  }
  .m-home-goods__button {
    display: block;
    width: 100%;
  }
  .m-title__arrows {
    max-width: 100%;
  }
  .m-why {
    padding: 55px 0 0 0;
    border: 0;
    background: transparent;
  }
  .m-why__title {
    margin: 0 0 50px 0;
    font-size: 40px;
    font-weight: 400;
  }
  .m-why-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto 70px auto;
    text-align: center;
  }
  .m-why_wrap {
    background: url("../images/why_bg_mob.png") no-repeat center top 150px;
    background-size: 100% auto;
  }
  .m-delivery {
    padding: 55px 0 120px 0;
    background: transparent;
  }
  .m-delivery__title {
    margin: 0 0 75px 0;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
  }
  .m-delivery__title br {
    display: none;
  }
  .m-delivery-card {
    margin: 0 auto 60px auto;
    text-align: center;
  }
  .m-delivery-card__title {
    margin-bottom: 20px;
  }
  .m-title__centered {
    padding-top: 40px;
  }
  .m-delivery__info {
    text-align: center;
  }
  .m-delivery__button {
    display: block;
    max-width: 305px;
    margin: 0 auto;
  }
  .m-reviews {
    padding: 0;
  }
  .m-reviews > .container {
    padding-top: 80px;
    padding-bottom: 210px;
  }
  .m-reviews .m-title__arrows {
    display: none;
  }
  .m-reviews .swiper-pagination {
    display: block;
  }
  .m-reviews .m-title__button {
    border: 1px solid #b4935b;
    background: #ffffff;
    text-align: center;
    color: #313131;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 400;
  }
  .m-seo {
    margin: 0;
    padding: 30px 0;
    background: url("../images/seo_bg_mob.png") no-repeat right 10px top;
  }
  .m-seo__image {
    display: none;
  }
  .m-seo__more {
    display: block;
  }
  .m-seo__content p {
    font-size: 14px;
    font-weight: 400;
  }
  .m-seo__content {
    display: block;
    min-height: auto;
    max-height: 194px;
    overflow: hidden;
    padding: 0;
  }
  .m-footer {
    padding-bottom: 40px;
  }
  .m-home-logo {
    padding: 60px 0 0 0;
  }
  .m-footer__button {
    max-width: 180px;
    margin: 24px auto 35px auto;
  }
  .m-footer__phone {
    margin: 0;
    text-align: center;
  }
  .m-footer__creators {
    margin-top: 60px;
    text-align: center;
  }
  .m-footer__title {
    margin: 35px 0 22px 0;
    text-align: center;
  }
  .m-footer__social {
    justify-content: center;
    padding-bottom: 0;
  }
  .m-footer__social a {
    margin: 0 25px 10px 25px;
  }
  .m-footer__pay {
    margin: 0 auto;
  }
  .m-footer__link {
    width: calc(100% + 30px);
    margin: -1px 0 0 -15px;
    padding: 20px 0;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    background: url("../images/nav_arrow.png") no-repeat right 35px center;
    color: #313131;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  .m-footer__link_active {
    background: #ededed url("../images/nav_arrow_opened.png") no-repeat right 35px center !important;
    color: #313131 !important;
  }
  .m-footer__nav {
    display: none;
    width: calc(100% + 30px);
    margin: 0 0 0 -15px;
    padding: 0 0 10px 0;
    background: #ededed;
    text-align: center;
  }
  .m-footer__nav li a {
    margin: 0;
    padding: 9px 0;
    color: #313131;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 300;
  }
  .m-breadcrumps {
    padding: 20px 0;
  }
  .m-good__image {
    height: 350px;
    padding: 10px;
  }
  .m-good__title {
    margin: 20px 0;
    font-size: 48px;
  }
  .m-good__price {
    margin-bottom: 30px;
  }
  .m-home-goods__centered_good {
    padding: 40px 0 20px 0;
  }
  .m-good-stocks .m-home-goods__centered {
    padding-bottom: 20px;
  }
  .m-stock__content {
    padding: 30px 20px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.7);
  }
  .m-good-stocks {
    padding-bottom: 30px;
  }
  .m-quote__image img,
  .m-quote__image svg {
    position: relative;
    top: auto;
    left: auto;
    margin: 0;
  }
  .m-quote__image {
    margin: 0 auto 30px auto;
  }
  .m-quote__content {
    padding: 20px 0;
  }
  .m-quote__name {
    margin-top: 30px;
  }
  .m-quote {
    padding-bottom: 20px;
  }
  .m-why {
    padding-top: 20px;
  }
  .m-about {
    padding: 20px 0;
  }
  .m-about__title {
    margin-bottom: 30px;
    font-size: 42px;
  }
  .m-about__image {
    width: 100%;
    margin: 20px auto;
  }
  .m-how__cards {
    padding-top: 30px;
  }
  .m-how {
    padding-bottom: 30px;
  }
  .m-article h1 {
    font-size: 48px;
  }
  .m-article h2 {
    font-size: 38px;
  }
  .m-article h3 {
    font-size: 28px;
  }
  .m-article p {
    margin-bottom: 30px;
  }
  .m-article .table {
    margin: 30px 0;
  }
  .m-zones {
    padding: 30px 0;
  }
  .m-zones .m-home-goods__centered {
    padding-bottom: 20px;
  }
  .m-block {
    padding: 30px 0;
  }
  .m-block .m-home-goods__centered {
    padding-bottom: 20px;
  }
  .m-basket__subtitles {
    display: none;
  }
  .m-basket {
    padding: 30px 0;
  }
  .m-basket .justify-content-center {
    justify-content: flex-start !important;
  }
  .m-basket__title {
    margin-bottom: 20px;
    font-size: 48px;
  }
  .m-form {
    padding: 30px 0;
  }
  .m-form__title {
    margin-bottom: 20px;
    font-size: 48px;
  }
  .m-basket-card__delete {
    padding: 10px;
    top: 10px;
    right: 0;
  }
  .m-form__text {
    margin-bottom: 30px;
  }
  .m-form__row .m-form__input {
    max-width: 49%;
  }
  .m-stocks .m-home-goods__centered {
    padding-bottom: 30px;
  }
  .m-stocks {
    padding: 30px 0;
  }
  .m-contacts {
    padding: 30px 0;
  }
  .m-contacts__main {
    justify-content: space-around;
    margin-top: 30px;
    padding: 5px 20px;
  }
  .m-contacts-card {
    width: 100%;
    margin: 10px 0;
  }
  #map {
    height: 350px;
  }
  .m-feedback {
    margin: 30px 0;
  }
  .m-feedback__form {
    padding: 30px 20px;
  }
  .m-feedback_light {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .m-reviews {
    background: url("../images/reviews_bg_mob.png") no-repeat center center;
    background-size: 100% 100%;
  }
}
